import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import { useFetch, useFetchSingle, useUpdateSingle } from '../API/Api';
import { Button, Modal } from 'antd';
import { useQueryClient } from 'react-query';

const Declared=()=> {

   
  let userData = localStorage.getItem('data')
  let extractData = userData? JSON.parse(userData):[]
  let userId = extractData?.id

  let apinameForBets = `user_info/${userId}`

  const {
    isLoading: fetchLoading,
    isError,
    data: fetchAllData,
    error,
    isFetching,
    refetch,
    isSuccess,
    isPreviousData,
    isRefetching,
  } = useFetch(apinameForBets);

  let apiForCreator = 'creator_resolve' 
  let apiForJoiner = 'joiner_resolve' 

  // const { mutate:mutateResult, isSuccess: isSuccessResult, isError: isErrorResult,error: errorResult,data: Updated_Result,isLoading: isLoadingResult } = useUpdateSingle()




  const [data,setData]:any = useState([])





  useEffect(()=>{
    if(fetchAllData){
      let created = fetchAllData?.user_bets
      let joined = fetchAllData?.joiner_data
      
      setData([...created,...joined])
    }
  },[fetchAllData])







const { mutate, isSuccess:isSuccessResolve, isError:isErrorResolve,error:errorResolve,data: Resolved_response,isLoading } = useUpdateSingle()






const currentAddress:any = localStorage.getItem('currAddress')
const [winners,setWinners]:any = useState([])

const handleResolve = (data:any) => {



  setWinners(data?.winner)
 
debugger
  if(extractData?.name == data?.creator){
    if(currentAddress.toLowerCase() != data?.creator_add.toLowerCase()){
      setResultMessage('You participated from different wallet address.')
      setResultModal(true)
    }else{
    let tempData = {
      is_creator_resolved: true
    }
    
     
      let finalData = {
          id:data?.bet_id,
          apiname:apiForCreator,
          data:tempData
      }
      mutate(finalData);
    }
  }else{

    if(currentAddress.toLowerCase() != data?.joiner_add.toLowerCase()){
      setResultMessage('You participated from different wallet address.')
      setResultModal(true)
    }else{


    let tempData = {
      is_joiner_resolved: true
    }
    
     
      let finalData = {
          id:data?.id,
          apiname:apiForJoiner,
          data:tempData
      }
      mutate(finalData);

    }
  }
 
  }

  const [resultModal,setResultModal] = useState(false)

  const [resultMessage,setResultMessage] = useState('')


const queryClient = useQueryClient();
// queryClient.invalidateQueries(['Fetch',apinameForBets]);


useEffect(()=>{
  if(isSuccessResolve && winners?.winners?.length>0){
    if(winners?.winners?.length>0){
      const exists = winners?.winners?.some((addr:any) => addr.toLowerCase() == currentAddress?.toLowerCase());
      if(exists){
        setResultModal(true)
        setResultMessage(`You won the bet with amount of ${winners?.payout_amount} ETH. (Added to pending balance)`)
      }else{
        setResultModal(true)
        setResultMessage('You lost the bet.')
      }

    }else{
      setResultModal(true)
      setResultMessage('You lost the bet.')
    }
    
    queryClient.invalidateQueries(['Fetch',apinameForBets]);
  }
},[isSuccessResolve,winners])




  return (
    <>
     <Header/>

    


{data?.map((e:any)=>{

  if(e?.is_admin_resolved){

    const date = new Date(e?.created_at);

    const options:any = { 
      weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZoneName: 'short'
    };
    
    const formattedDate = date.toLocaleString('en-US', options);
    return(
      
      <div key={e?.bet_id} className="container mx-auto flex justify-center pt-10">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 w-96 md:w-96 lg:w-1/2 	">
            <div className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white border-2 border-green-200 shadow-xl">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className='flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center items-center  space-x-3 justify-between'>
                  <div>
                  <span className='text-green-600'>Created By {extractData?.name == e?.creator ?'You':e?.creator}</span>
                  </div>
      
                  <div>
                  {/* <span className='text-green-600'>Created At - {e?.created_at?.split('T')[0]}</span> */}
                  <span className='text-green-600'>Created At - {formattedDate.split('GMT')[0]}</span>
                  </div>
                  </div>
                <div className="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center items-center  space-x-3 justify-between">
                  <div >
                  <span className="truncate text-sm font-medium text-gray-900">{e?.match?.team_one} Vs {e?.match?.team_two}</span>
                  <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 ml-3 py-0.5 text-xs font-medium text-red-600 ring-1 ring-inset ring-green-600/20">World Cup</span>
                  </div>
                  <div>
                  <h3 className="pt-3 truncate text-sm font-medium text-blue-600">Amount - {e?.amount} ETH</h3>
                  </div>
                </div>
      
      
      
      
                <div className="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center items-center  space-x-3 justify-between">
                  <div >
      
                  </div>
                  <div>
                  </div>
                </div>
        
        
                           <p className="mt-3 truncate text-sm text-center text-gray-500">Winning Team : {e?.winning_team? e?.winning_team : '-'}</p>
      
                
              </div>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200 bg-gray-200">
                <div className="-ml-px flex w-0 flex-1">
                  <Button onClick={()=>handleResolve(e)} 
                  // disabled={e?.is_creator_resolved || e?.is_joiner_resolved}
                   type='primary' className="cursor-pointer relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 bg-blue-500  text-sm font-semibold text-white">
                    {/* {extractData?.name == e?.creator_name ? 'Created' :'Joined'} */}
                    {e?.is_creator_resolved || e?.is_joiner_resolved ? 'Resolved' : 'Get Result'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        )
  }else if(e?.creator_data?.is_admin_resolved){

    const date = new Date(e?.creator_data?.created_at);

    const options:any = { 
      weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',
      hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZoneName: 'short'
    };
    
    const formattedDate = date.toLocaleString('en-US', options);
    return(

      <div key={e?.bet_id} className="container mx-auto flex justify-center pt-10">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 w-96 md:w-96 lg:w-1/2 	">
            <div className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white border-2 border-green-200 shadow-xl">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
              <div className="flex-1 truncate">
                <div className='flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center items-center  space-x-3 justify-between'>
                  <div>
                  <span className='text-green-600'>Created By {e?.creator_data?.creator}</span>
                  </div>
      
                  <div>
                  {/* <span className='text-green-600'>Created At - {e?.creator_data?.created_at?.split('T')[0]}</span> */}
                  <span className='text-green-600'>Created At - {formattedDate.split('GMT')[0]}</span>
                  </div>
                  </div>
                <div className="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center items-center  space-x-3 justify-between">
                  <div >
                  <span className="truncate text-sm font-medium text-gray-900">{e?.creator_data?.match?.team_one} Vs {e?.creator_data?.match?.team_two}</span>
                  <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 ml-3 py-0.5 text-xs font-medium text-red-600 ring-1 ring-inset ring-green-600/20">World Cup</span>
                  </div>
                  <div>
                  <h3 className="pt-3 truncate text-sm font-medium text-blue-600">Amount - {e?.amount} ETH</h3>
                  </div>
                </div>
      
      
      
      
                <div className="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center items-center  space-x-3 justify-between">
                  <div >
      
                  </div>
                  <div>
                  </div>
                </div>
        
        
                           <p className="mt-3 truncate text-sm text-center text-gray-500">Winning Team : {e?.creator_data?.winning_team ? e?.creator_data?.winning_team :'-'}</p>
      
                
              </div>
            </div>
            <div>
              <div className="-mt-px flex divide-x divide-gray-200 bg-gray-200">
                <div className="-ml-px flex w-0 flex-1">
                  <Button onClick={()=>handleResolve(e)} 
                  // disabled={e?.is_creator_resolved || e?.is_joiner_resolved} 
                  type='primary' className="cursor-pointer relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 bg-blue-500  text-sm font-semibold text-white">
                    {/* {extractData?.name == e?.creator_name ? 'Created' :'Joined'} */}
                    {e?.is_creator_resolved || e?.is_joiner_resolved ? 'Resolved' : 'Get Result'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        )
  }


 
 
 
})}



<Modal
 maskClosable={false}
  title={'Result'}
  // visible={open}
  open={resultModal}
  // onOk={this.handleOk}
  onCancel={()=>{
    setResultMessage('')
    setResultModal(false)}}
  footer={[<>
  <button className='px-3 py-2 border-2 border-black rounded-md' onClick={()=>{
     setResultMessage('')
    setResultModal(false)}}>Close</button>
  
  </>]}
>


<div className="mb-4 md:w-full" >
  <h3 className='text-blue-600 text-center'>{resultMessage}</h3>
  </div>

  


</Modal>
 
</>
  );
}

export default Declared;
