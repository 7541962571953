import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import { useFetch } from './API/Api';
import ModalComp from './Components/Modal';
import Header_Test from './Components/Header_Test';
import cricketbg from '../src/Images/cricket.png'
function App() {

  //Bet Address - '0xd692f06227AF55A7FDb9F97389F6331c3dB3eAa6'

  const [openModal,setOpenModal] = useState(false)
  const [currentData,setCurrentData] = useState('New Matches')

  let apiname = 'matches'

  let apinameForBets = 'bet_list'

  const {
    isLoading: fetchLoading,
    isError,
    data: fetchAllData,
    error,
    isFetching,
    refetch,
    isSuccess,
    isPreviousData,
    isRefetching,
  } = useFetch(currentData==='New Matches'?apiname:apinameForBets);



  const [data,setData]:any = useState([])

  let userData = localStorage.getItem('data')
  let extractData = userData? JSON.parse(userData):[]



  useEffect(()=>{
    let sortedData;
    if(currentData==='New Matches'){
      // sortedData = fetchAllData?.sort((a:any, b:any) => b?.id - a?.id);
      setData(fetchAllData)
    }else{
      let tempData = fetchAllData?.filter((e:any)=>extractData?.id != e?.creator?.id)
      sortedData = tempData?.sort((a:any, b:any) => b?.id - a?.id);
      setData(sortedData)
    }
  },[currentData,fetchAllData])



  const[title,setTitle] = useState('')

  const [getCreated,setGetCreated]:any = useState()
  const [getJoin,setGetJoin]:any = useState()


  const handleCreate=(data:any,e:any)=>{
    setTitle('Create a Bet')
    setOpenModal(true)
    setGetCreated(e)
    // localStorage.setItem('createData',JSON.stringify(e))

  }


  const handleJoin=(data:any,e:any)=>{
    setTitle('Join a Bet')
    setOpenModal(true)
    setGetJoin(e)
    // localStorage.setItem('joinData',JSON.stringify(e))
  }



 


  return (
    <>
     <Header/>

     {/* <Header_Test/> */}

     <div className="container mx-auto flex justify-center text-center pt-10">
  <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-2 gap-6 w-1/2 	">
      <div className={currentData==='New Matches'?'border-b-2 border-blue-200 cursor-pointer':'cursor-pointer'} onClick={()=>setCurrentData('New Matches')}>
    New Matches
      </div>
      <div className={currentData==='Available For Join'?'border-b-2 border-blue-200 cursor-pointer':'cursor-pointer'} onClick={()=>setCurrentData('Available For Join')}>
Available For Join
      </div>
     </div>
     </div>


{data?.map((e:any)=>{
  if(currentData==='New Matches'){
   
 return(
    <div key={e?.id}>

<div  className="container mx-auto flex justify-center pt-6">
  <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 w-96 md:w-96 lg:w-1/2 	">
    <div className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white border-2 border-green-200 shadow-xl">
    <div className="flex w-full items-center justify-between space-x-6 p-6">
      <div className="flex-1 truncate">
        <div className="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center items-center  space-x-3 justify-between">
          <div >
          <span className="truncate text-sm font-medium text-gray-900">{e?.team_one?.name} Vs {e?.team_two?.name}</span>
          <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 ml-3 py-0.5 text-xs font-medium text-red-600 ring-1 ring-inset ring-green-600/20">World Cup</span>
          </div>
          <div>
          <h3 className="pt-3 truncate text-sm font-medium text-blue-600">Amount must be - 0.1 - 0.5 (ETH)</h3>
          </div>
        </div>

     


       
        
        <p className="mt-3 truncate text-sm text-center text-gray-500">Player Capacity - {e.player_capacity}</p>
      </div>
    </div>
    <div>
      <div className="-mt-px flex divide-x divide-gray-200">
        <div className="flex w-0 flex-1">
          <button onClick={(data)=>handleCreate(data,e)} className="cursor-pointer relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
           
            Create a Bet
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
    </div>
  )
  }else{
    
    let joiners = e?.joiners?.map((e:any)=>e?.joiner_name)
    const exists = joiners?.some((addr:any) => addr.toLowerCase() === extractData?.name?.toLowerCase());

    if(!exists && e?.is_admin_resolved == false){
    return(
      <div key={e?.id}>
  
  <div key={e?.id} className="container mx-auto flex justify-center pt-6">
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 w-96 md:w-96 lg:w-1/2 	">
      <div className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white border-2 border-green-200 shadow-xl">
      <div className="flex w-full items-center justify-between space-x-6 p-6">
        <div className="flex-1 truncate">
            <p className='text-green-600'>Created By : {e?.creator?.name}</p>
          <div className="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center items-center  space-x-3 justify-between">
            <div >
            <span className="truncate text-sm font-medium text-gray-900">{e?.match?.team_one?.name} Vs {e?.match?.team_two?.name}</span>
            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 ml-3 py-0.5 text-xs font-medium text-red-600 ring-1 ring-inset ring-green-600/20">World Cup</span>
            </div>
            <div>
            <h3 className="pt-3 truncate text-sm font-medium text-blue-600">Entry Amount - {e?.amount} ETH</h3>
            </div>
          </div>




          <div className="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center items-center  space-x-3 justify-between">
            <div >
            <span className="mt-3 truncate text-sm text-center text-gray-500">Player Capacity - {joiners?.length+1}/{e?.match?.player_capacity}</span>

            </div>
            <div>
            <h3 className="pt-3 truncate text-sm font-medium text-black">Joiners - {joiners?.join(' , ')}</h3>
            </div>
          </div>
  
  
         
          
        </div>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="-ml-px flex w-0 flex-1">
            {
              joiners?.length+1 >= e?.match?.player_capacity?
              <>
              <button className=" cursor-pointer relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-400">
              Lobby is Full
            </button>
              </>:
              <>
              <button onClick={(data)=>handleJoin(data,e)} className=" cursor-pointer relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
              Join a Bet
            </button>
              </>
            }
            {/* <button className=" cursor-pointer relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
              Join a Bet
            </button> */}
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  
      </div>
    )
  }
}
 
})}


<ModalComp open={openModal} setOpen={setOpenModal} title={title} getJoin={getJoin} getCreated={getCreated}/>

{/* <img src={cricketbg} className='cricketIMG'  style={{position:'absolute',top:'40%',left:'70%',zIndex:-1}} height={300} width={400}/> */}
 
</>
  );
}

export default App;
