import React, { useEffect, useRef, useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { useCreate } from '../API/Api'
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useMutation } from 'react-query';
import { Button } from 'antd';

const Login = () => {
  const[data,setData] = useState({email:'',password:''})
  const apiname = 'login'

  const buttonRef:any = useRef(null);

  useEffect(() => {
    
    const handleKeyDown = (event:any) => {
      if (event.key === 'Enter') {
        console.log("Enter key pressed."); 
        buttonRef.current?.click();
      }
    };

    
    document.addEventListener('keydown', handleKeyDown);

    
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); 



  const navigate = useNavigate()

  const[message,setMessage] = useState('')

  const { error, mutate, isError, isLoading, data:responseData,isSuccess }: any = useMutation(async (data: any) => {
    const postapicall = await axios.post(`${process.env.REACT_APP_BASE_URL}/${apiname}/`, data)
    return postapicall.data
})


// console.log('process.env.BASE_URL',process.env.REACT_APP_BASE_URL)
  // const {mutate:dataMutate,isSuccess:dataSuccess,isLoading:dataLoading,isError:dataIsError,data:responseData,error:dataError} = useCreate(apiname)

  const {
    register,
    handleSubmit,
    formState,
  }:any = useForm({
    mode:"onChange"
});

  const { errors } = formState;

  const onSubmit = (datad:any,e:any)=> {
    e.preventDefault()
    mutate({email:datad?.email,password:datad?.password})
  }

useEffect(()=>{
  if(isSuccess && responseData){
    setMessage('')
    if(responseData?.name === 'Admin'){
      localStorage.setItem('data',JSON.stringify(responseData));
      navigate('/Admin')
    }else{
      localStorage.setItem('data',JSON.stringify(responseData));
      navigate('/')  
    }
  }
},[isSuccess,responseData])

useEffect(()=>{
  if(isError && error){
    setMessage(error?.response?.data?.error)
  }
},[isError,error])

console.log(errors)



  return (
    <div className="antialiased bg-gray-200 text-gray-900 font-sans">
    <div className="flex items-center h-screen w-full">
      <div className="w-full bg-white rounded shadow-lg p-8 m-4 md:max-w-sm md:mx-auto">
      <span className="block w-full text-xl uppercase font-bold mb-4">Login</span>      
      <p className='text-red-600'>{message&&message}</p>
        {/* <form className="mb-4" > */}
        {/* <div className="mb-4 md:w-full">
            <label htmlFor="email" className="block text-xs mb-1">Name</label>
            <input 
            {...register('name', { required: true })}
            value={data?.name}
            onChange={(e)=>setData({...data,name:e.target.value})} 
            className="w-full border rounded p-2 outline-none focus:shadow-outline"  name="name" id="email" placeholder="Username"/>
            <p className='text-red-600'>{errors?.name&&'Name is required'}</p>
          </div> */}
          <div className="mb-4 md:w-full">
            <label htmlFor="email" className="block text-xs mb-1">Email</label>
            
            <input 
            {...register('email', { required: true })}
            // onChange={(e)=>setData({...data,email:e.target.value})} 
            className="w-full border rounded p-2 outline-none focus:shadow-outline"  name="email" id="email" placeholder="Email"/>
            <p className='text-red-600'>{errors?.email&&'Email is required'}</p>
          </div>
          <div className="mb-6 md:w-full">
            <label htmlFor="password" className="block text-xs mb-1">Password</label>
            <input 
            {...register('password', { required: true })}
            // onChange={(e)=>setData({...data,password:e.target.value})} 
            className="w-full border rounded p-2 outline-none focus:shadow-outline" type="password" name="password" id="password" placeholder="Password"/>
            <p className='text-red-600'>{errors?.password&&'Password is required'}</p>
          </div>
          <div className='justify-between flex'>
            <div>
          <Button loading={isLoading} ref={buttonRef} className="bg-green-500 hover:bg-green-700 text-white uppercase text-sm font-semibold  rounded"  onClick={handleSubmit(onSubmit)}>Login</Button>
          </div>
          <div>
        <Link className="text-blue-700 text-center text-sm" to="/Register">Register?</Link>
        </div>
        </div>
        {/* </form> */}
    </div>
  </div>
</div>
  )
}

export default Login