import { useMutation, useQuery } from "react-query";
import axios from "axios";
import Axiosinstance from "./Interceptor";

export const useCreate = (apiname?:any) => useMutation(
    async (data: any) => {
        const postapicall = await Axiosinstance.post(`${process.env.REACT_APP_BASE_URL}/${apiname}/`, data)
        return postapicall.data
    })



    export const FetchAll=(apiname?:any)=>{
       
            return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}/${apiname}/`)
       
    }


    export const useFetch = (apiname?:any) => {

    
        return useQuery(['Fetch',apiname],()=>FetchAll(apiname),{
            refetchOnMount:true,
            refetchOnWindowFocus:false,
            keepPreviousData:true,
            
            select:(data:any)=>{
               
                // const results = data?.data?.results.map((data:any)=>{
                //     return data
                // })
                return data?.data
            },
    
            
        })
    }



const FetchSingleApi=(id?:any,apiname?:any)=>{
    return Axiosinstance.get(`${process.env.REACT_APP_BASE_URL}/${apiname}/${id}/`)
}


export const useFetchSingle = (id?:any,apiname?:any) => {
    return useQuery(['Single',id,apiname],()=>FetchSingleApi(id,apiname),{
        select:(data:any)=>{
            const results = data.data?.bet
            return results
        }
    })
}



export const Update = async (input: any) => {
    try {
        if(input?.id){
            const url = `${process.env.REACT_APP_BASE_URL}/${input.apiname}/${input.id}/`;
            const response = await Axiosinstance.patch(url, input.data);
            return response.data;

        }else{
            const url = `${process.env.REACT_APP_BASE_URL}/${input.apiname}/`;
            const response = await Axiosinstance.patch(url, input.data);
            return response.data;
        }
        // const apiUrl = status ? `${url}?edit=yes` : url;
        // const response = await Axiosinstance.patch(url, input.data);
        // return response.data;
    } catch (error) {
        // Handle or throw the error
        console.error('API call error:', error);
        throw error;
    }
};
export const useUpdateSingle = () => {
    return useMutation((input: any) => Update(input))
}