import { Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link, useParams,useNavigate } from 'react-router-dom';
import { useCreate, useFetchSingle, useUpdateSingle } from '../../API/Api';
import { useForm } from 'react-hook-form';
import BetContract from '../../ABI/Bet.json'
import { ethers } from 'ethers';
import { Modal } from 'antd';


const EditBet = () => {
    const apiname = 'get'
    const { id } = useParams()
    const {data} = useFetchSingle(id,apiname)

    const navigate = useNavigate()

    let apinameForWinner = 'winner'

    const { mutate, isSuccess, isError,error,data: Updated_response,isLoading } = useUpdateSingle()

    const { mutate: mutateWinner, isSuccess: isSuccessWinner, isError:isErrorWinner,error:errorWinner,data: winner_response,isLoading: isLoadingWinner } = useCreate(apinameForWinner)

    const { register, handleSubmit, setError, formState: { errors,dirtyFields,isDirty }, reset, setValue, getValues } = useForm();


    const [infoModal,setInfoModal] =useState(false)
    const [infoMessage,setInfoMessage] =useState('')


     


useEffect(()=>{
  if(data){
    reset()
    // setValue('score',data?.score)
    // setExpectedScore(data?.score)
  }
},[data])

useEffect(()=>{
  if(isErrorWinner){
    setErrorMessage('')
    setInfoMessage(errorWinner?.response?.data?.error)
    setInfoModal(true)
  }
},[isErrorWinner,errorWinner])

// const [isDone,setIsDone] = useState(false)


const contractFunction=async()=>{

  if (typeof window.ethereum !== 'undefined') {
    setLoading(true)
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const testContract = new ethers.Contract(betAddress, BetContract.abi, signer);

    let winningTeam = parseInt(getValues('team'));
    


    
    testContract.on("BetResolved", (betId, winners, payoutAmount) => {
    
      let finalValue = ethers.formatEther(payoutAmount)
      let dummy={
        bet_id:data?.id,
         winners:winners,
          payout_amount:Number(finalValue).toFixed(4)
      }
      mutateWinner(dummy)
   
  });


    
    try {
      const transaction = await testContract.resolveBet(
        Number(data?.bet_contract_id),
        expectedScore,
        winningTeam,
      );
     
      
      const receipt = await transaction.wait(); 
      
      testContract.off("BetResolved");
      
      setLoading(false)
      setBetResolved(true)

  } catch (error) {
      console.error("Error creating bet", error?.info?.error?.message);
      setBetResolved(false)
      setErrorMessage(error?.info?.error?.message)
      setInfoMessage(error?.info?.error?.message)
      setInfoModal(true)
      setLoading(false)
      if(error?.info?.error?.message =='execution reverted: No winner for this bet'){
      let dummy={
        bet_id:data?.id,
         winners:[],
          payout_amount:0.0
      }
      mutateWinner(dummy)
      }

  }
}
}


let apiForPatch = `edit`

    const onSubmit = async(dataTo) => {



      await contractFunction()


      const winningTeam = dataTo?.team == 0? data?.match?.team_one?.id : data?.match?.team_two?.id 

      
      let tempData = {
        winning_team:Number(winningTeam),
        score:Number(dataTo?.score),
        status:'CLOSED',
        is_admin_resolved:true
      }
      
       
        let finalData = {
            id:id,
            apiname:apiForPatch,
            data:tempData
        }
        mutate(finalData);
    }

    const [errorMessage,setErrorMessage] = useState('')
    const [loading,setLoading] = useState(false)

    const [resolveData,setResolveData] = useState()

    const [betResolved,setBetResolved] = useState(false)

    let betAddress = '0xd692f06227AF55A7FDb9F97389F6331c3dB3eAa6'

    useEffect(()=>{
        if(isSuccess && betResolved){
          setInfoMessage('Successfully Resolved')
          setInfoModal(true)
          setBetResolved(false)
           
        }
    },[isSuccess,betResolved])


   

  

    // useEffect(()=>{
    //   if(betResolved){

    //     let dummy={
    //       bet_id:data?.id,
    //        winners:resolveData?.winners,
    //         payout_amount:resolveData?.payout_amount
    //     }
    //     // let tempData = {
    //     //   id:resolveData?.betId,
    //     //   apiname:apinameForWinner,
    //     //   data:dummy
    //     // }
    //     mutateWinner(dummy)
    //     setBetResolved(false)
    //   }
    // },[betResolved])

    // useEffect(()=>{
    //   if(isSuccessWinner){
        
    //     // navigate('/Admin')
    //   } 
    // },[isSuccessWinner])




    const [expectedScore, setExpectedScore] = useState('');

    const [isValid, setIsValid] = useState(true);
    const validateInput = (value) => {
      const regex = /^\d+$/; // Regex to check for numeric input
      return regex.test(value);
    };
    
    const handleExpectedScoreChange = (e) => {
      const value = e.target.value;
      if (value === '' || validateInput(value)) { 
          setExpectedScore(value);
          setValue('score',value)
          setIsValid(true); 
      } else {
          setIsValid(false); 
      }
    };

  return (
    <div className="App">
      <div className="container mx-auto px-4 sm:px-8 text-center">
        <div className="py-10">
          <div>
            <h1 className="text-2xl font-semibold leading-tight">Update Score</h1>
            <p className='text-red-600'>{errorMessage&&errorMessage}</p>

            <div className="mb-6 w-full mt-5">
            {/* <label htmlFor="password" className="block text-xs mb-1">Status</label> */}
         <select 
         {...register('team', { required: true })}
         className="w-full md:w-1/2 lg:w-1/3 border rounded p-2 outline-none focus:shadow-outline" 
        //  onChange={(e) => setSelectedTeam(e.target.value)} 
         >
        <option value="">Winning Team</option>
        <option value={0}>{data?.match?.team_one?.name}</option>
            <option value={1}>{data?.match?.team_two?.name}</option>
      </select>
         <p className='text-red-600'>{errors?.team&&'Winning is required'}</p>
         </div>


         <div className="mb-6 md:w-full mt-5">
            {/* <label htmlFor="password" className="block text-xs mb-1">Real Score</label> */}
            {/* <input 
            {...register('real_score', { required: true })}
            // onChange={(e)=>setData({...data,password:e.target.value})} 
            className="w-full md:w-1/2 lg:w-1/3 border rounded p-2 outline-none focus:shadow-outline" type="number"   placeholder="Score"/> */}
            {/* <p className='text-red-600'>{errors?.password&&'Password is required'}</p> */}


            <input
                type="text" // Use 'text' to manage input formatting
                {...register('score', { required: true })}
                className={`w-full md:w-1/2 lg:w-1/3 border rounded p-2 outline-none focus:shadow-outline ${!isValid ? 'border-red-500' : ''}`}
                value={expectedScore}
                onChange={handleExpectedScoreChange}
                placeholder="Real Score"
                required
            />
            <p className='text-red-600'>{errors?.score&&'Score is required'}</p>
          </div>

{/* 
            <div className="mb-6 w-full mt-5">
         <select 
         {...register('status', { required: true })}
         className="w-full md:w-1/2 lg:w-1/3 border rounded p-2 outline-none focus:shadow-outline" 
         >
        <option value="">Status</option>
        <option value={'OPEN'}>Open</option>
            <option value={'CLOSED'}>Close</option>
      </select>
         </div> */}

            

          <div>
          <Button className="bg-green-500 hover:bg-green-500 text-white uppercase text-sm font-semibold  rounded" disabled={isLoading || loading}  onClick={handleSubmit(onSubmit)}>{isLoading || loading? 'Updating...':'Update And Resolve'}</Button>
          </div>
          </div>
        
          </div>
        </div>

        <Modal
 maskClosable={false}
  title={'Status'}
  // visible={open}
  open={infoModal}
  // onOk={this.handleOk}
  onCancel={()=>{
    setInfoMessage('')
    setInfoModal(false)}}
  footer={[<>
  <button className='px-3 py-2 border-2 border-black rounded-md' onClick={()=>{
      setInfoMessage('')
      setInfoModal(false)
      navigate('/Admin')
  }}>Close</button>
  
  </>]}
>


<div className="mb-4 md:w-full" >
  <h3 className='text-green-600 text-center'>{infoMessage}</h3>
  </div>

  


</Modal>

      </div>
  );
}

export default EditBet;
