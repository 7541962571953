import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useCreate } from '../API/Api'
import { useForm } from 'react-hook-form';
import { Button } from 'antd';

const Register = () => {
  // const[data,setData] = useState({name:'',email:'',password:''})
  const apiname = 'register'
  const [message,setMessage] = useState('')

  const buttonRef:any = useRef(null);

  useEffect(() => {
    
    const handleKeyDown = (event:any) => {
      console.log("Key pressed:", event.key); 
      if (event.key === 'Enter') {
        console.log("Enter key pressed."); 
        buttonRef.current?.click();
      }
    };

    
    document.addEventListener('keydown', handleKeyDown);

    
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []); 

  const [errorMessage,setErrorMessage] = useState('')

  const {mutate:dataMutate,isSuccess:dataSuccess,isLoading:dataLoading,isError:dataIsError,error:dataError}:any = useCreate(apiname)

  const {
    register,
    handleSubmit,
    formState,
    reset
  }:any = useForm({
    mode:"onChange"
});

  const { errors } = formState;

  const onSubmit = (data:any,e:any)=> {
    e.preventDefault()
    dataMutate({name:data?.name,email:data?.email,password:data?.password})
  }

  useEffect(()=>{
    if(dataSuccess){
      setMessage('Successfully Registered')
      setErrorMessage('')
      reset()
    }
  },[dataSuccess])

  

  useEffect(()=>{
    if(dataIsError && dataError){
      setErrorMessage(dataError?.response?.data?.email)
      setMessage('')
    }
  },[dataIsError,dataError])

  

  return (
    <div className="antialiased bg-gray-200 text-gray-900 font-sans">
    <div className="flex items-center h-screen w-full">
      <div className="w-full bg-white rounded shadow-lg p-8 m-4 md:max-w-sm md:mx-auto">
      <span className="block w-full text-xl uppercase font-bold mb-4">Register</span>   
      <p className='text-green-600'>{message&&message}</p>   
      <p className='text-red-600'>{errorMessage&&errorMessage}</p>

        <form className="mb-4" >
        <div className="mb-4 md:w-full">
            <label htmlFor="email" className="block text-xs mb-1">Name</label>
            <input 
            {...register('name', { required: true })}
            // onChange={(e)=>setData({...data,name:e.target.value})} 
            className="w-full border rounded p-2 outline-none focus:shadow-outline"  name="name" id="email" placeholder="Username"/>
            <p className='text-red-600'>{errors?.name&&'Name is required'}</p>
          </div>
          <div className="mb-4 md:w-full">
            <label htmlFor="email" className="block text-xs mb-1">Email</label>
            <input 
            {...register('email', { required: true })}
            // onChange={(e)=>setData({...data,email:e.target.value})} 
            className="w-full border rounded p-2 outline-none focus:shadow-outline"  name="email" id="email" placeholder="Email"/>
            <p className='text-red-600'>{errors?.email&&'Email is required'}</p>
          </div>
          <div className="mb-6 md:w-full">
            <label htmlFor="password" className="block text-xs mb-1">Password</label>
            <input 
            {...register('password', { required: true })}
            // onChange={(e)=>setData({...data,password:e.target.value})} 
            className="w-full border rounded p-2 outline-none focus:shadow-outline" type="password" name="password" id="password" placeholder="Password"/>
            <p className='text-red-600'>{errors?.password&&'Password is required'}</p>
          </div>
          <div className='justify-between flex'>
            <div>
          <Button loading={dataLoading} ref={buttonRef} className="bg-green-500 hover:bg-green-700 text-white uppercase text-sm font-semibold  rounded" onClick={handleSubmit(onSubmit)}>Register</Button>
          </div>
          <div>
        <Link className="text-blue-700 text-center text-sm" to="/Login">Login?</Link>
        </div>
        </div>
        </form>
    </div>
  </div>
</div>
  )
}

export default Register