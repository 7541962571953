import React,{useState, useEffect} from 'react'
import { Modal, Button } from 'antd';
import { useCreate } from '../API/Api';
import { useForm } from 'react-hook-form';
import { ethers } from 'ethers';
import BetContract from '../ABI/Bet.json'
import { useQueryClient } from 'react-query';

const ModalComp = ({open,setOpen,title,getJoin,getCreated}) => {

  // let currAddress = localStorage.getItem('currAddress')
  // let createData = localStorage.getItem('createData')

  // let createData = localStorage.getItem('createData')
  // let getCreated = createData? JSON.parse(createData):[]


  // let joinData = localStorage.getItem('joinData')
  // let getJoin = joinData? JSON.parse(joinData):[]

  console.log(getCreated,getJoin)


  let userData = localStorage.getItem('data')
  let getUser = userData? JSON.parse(userData):[]



  const [userId, setUserId] = useState('');
  const [expectedScore, setExpectedScore] = useState('');
  const [selectedTeam, setSelectedTeam] = useState('');
  const [betAmount, setBetAmount] = useState('');




  const apinameForCreate = 'create'
  const apinameForJoin = 'join'
  const [message,setMessage] = useState('')

  const [loading,setLoading] = useState(false)

  const [errorMessage,setErrorMessage] = useState('')

  const {mutate:dataMutate,isSuccess:dataSuccess,isLoading:dataLoading,isError:dataIsError,error:dataError} = useCreate(title == 'Create a Bet'?apinameForCreate:apinameForJoin)

  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    getValues
  } = useForm({
    mode:"onChange"
});

  const { errors } = formState;



  const {
    register:registerJoiner,
    handleSubmit:handleSubmitJoiner,
    formState:formStateJoiner,
    reset:resetJoiner,
    setValue:setValueJoiner,
    getValues:getValuesJoiner
  } = useForm({
    mode:"onChange"
});

  const { errorsJoiner } = formStateJoiner;

  let betAddress = '0xd692f06227AF55A7FDb9F97389F6331c3dB3eAa6'

  const [currentAddress,setCurrentAddress] = useState('')

  useEffect(()=>{
    // if(localStorage.getItem('currAddress')){
      const walletFunc=async()=>{

        const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const address = await signer.getAddress()
      setCurrentAddress(address)
      const bet = new ethers.Contract(betAddress, BetContract.abi, signer);
      // const balance = await provider.getBalance(address);
      // setBalance(balance)
      setBetContract(bet)
      }
      walletFunc()
    // }
  },[open])

 

  // const onSubmit = ()=>{
  //   if(title == '')
  // }


// useEffect(()=>{
//     if(dataSuccess){
//       if(title === 'Create a Bet'){
//         setMessage('Successfully Created')
//       }else{
//       setMessage('Successfully Joined')
//       }
//       setErrorMessage('')
//       reset()
//     }
//   },[dataSuccess])

  

//   useEffect(()=>{
//     if(dataIsError && dataError){
//       setErrorMessage(dataError?.response?.data?.email)
//       setMessage('')
//     }
//   },[dataIsError,dataError])


const [betContract, setBetContract] = useState(null);
// const [betAmount, setBetAmount] = useState('');


const [amountAfterFee,setAmountAfterFee] = useState(0)

const feePercent = 5; 
const calculateTotalWithFee = (amountEth) => {
const amount = parseFloat(amountEth);
// const fee = amount * (feePercent * 10) / 1000;
const fee = amount * (feePercent / 100);
// setAmountAfterFee(amount + fee)
return amount + fee;
};



// useEffect(()=>{
//   if(betAmount){
//       const amount = parseFloat(betAmount);
//       const fee = amount * (feePercent / 100);
//       setAmountAfterFee((amount + fee).toFixed(6))
//   }
  
// },[betAmount])


useEffect(()=>{
  if(title === 'Join a Bet'){
    setBetAmount(getJoin?.amount)
  }
},[getJoin,open])



// let expectedScore = getValues('expected_score')
// let creatorTeam = getValues('creator_team')
// let joinerTeam = getValues('joiner_team')

// let expectedScoreJoiner = getValuesJoiner('expected_score')
// let creatorTeamJoiner = getValuesJoiner('creator_team')
// let joinerTeamJoiner = getValuesJoiner('joiner_team')

// const [betCreation,setBetCreation] = useState(false)


// const [betId,setBetId] = useState('')

// useEffect(()=>{
// if(betId){
//   localStorage.setItem('betId',betId)
// }
// },[])

const createBet = async (e) => {
  e.preventDefault();
  if (!validateInputs()) {
    return; 
}
  if (typeof window.ethereum !== 'undefined') {
    setLoading(true)
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(betAddress, BetContract.abi, signer);

    // const totalAmount = calculateTotalWithFee(betAmount);
    const totalAmount = betAmount;
    const ethValue = ethers.parseEther(totalAmount.toString());
    
    try {
      const transaction = await contract.createBet(
        getUser?.id,
        expectedScore,
        selectedTeam,
        { value: ethValue }
      );
     
      
      const receipt = await transaction.wait(); 
      

      const count = await contract.getAllBets();






      let tempCount = count?.length-1



    

          const tempData = {
            bet_contract_id: tempCount,
            creator: getUser?.id,
            match: getCreated?.id,
            creator_team: selectedTeam==0?getCreated?.team_one?.id:getCreated?.team_two?.id,
            amount: betAmount,
            expected_score: expectedScore,
            creator_add: currentAddress
          }
          
    
          dataMutate(tempData)

          setLoading(false)




     

     
  } catch (error) {
      console.error("Error creating bet", error?.info?.error?.message);
      setErrorMessage(error?.info?.error?.message)
      setLoading(false)
      // setError(error.reason)
  }
};
}



const joinBet = async (e) => {
  e.preventDefault();
  if (!validateInputs()) {
    return; 
}
  if (typeof window.ethereum !== 'undefined') {
    setLoading(true)
    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(betAddress, BetContract.abi, signer);

    // const totalAmount = calculateTotalWithFee(betAmount);
    const totalAmount = betAmount;
    const ethValue = ethers.parseEther(totalAmount.toString());
    

    try {
      const transaction = await contract.joinBet(
        getJoin?.bet_contract_id,
        expectedScore,
        selectedTeam,
        { value: ethValue }
      );
     

     
      const receipt = await transaction.wait(); 
      


     

      const tempData = {
        bet_contract_id: getJoin?.bet_contract_id,
        bet: getJoin?.id,
        joiner:getUser?.id,
        joiner_team: selectedTeam==0?getJoin?.match?.team_one?.id:getJoin?.match?.team_two?.id,
        amount: betAmount,
        expected_score: expectedScore,
        joiner_add:currentAddress
      }
      

      dataMutate(tempData)
      setLoading(false)

     
  } catch (error) {
      console.error("Error creating bet", error?.info?.error?.message);
      setErrorMessage(error?.info?.error?.message)
      setLoading(false)
      // setError(error.reason)
  }
};
}

















  const [openSuccessModal,setOpenSuccessModal] = useState(false)
  const[successModalMessage,setSuccessModalMessage] = useState(false)

  const queryClient = useQueryClient();

  let apinameForMatches = 'matches'

  let apinameForBets = 'bet/list'

  useEffect(()=>{
    if(dataSuccess){
      // setBetCreation(false)
      setLoading(false)
      if(title == 'Create a Bet'){
      setSuccessModalMessage('Successfully Created')
      }else{
        setSuccessModalMessage('Successfully Joined')
        queryClient.invalidateQueries(['Fetch',apinameForBets]);
      }
      setOpen(false)
      setOpenSuccessModal(true)
      // setOpen(false)
      setExpectedScore('')
setSelectedTeam('')
setBetAmount('')
      // queryClient.invalidateQueries(['Fetch',apinameForMatches]);
      // queryClient.invalidateQueries(['Fetch',apinameForMatches]);
    }
  },[dataSuccess])

  useEffect(()=>{
    if(dataIsError && dataError){
      setErrorMessage(dataError?.response?.data?.email)
      setMessage('')
    }
  },[dataIsError,dataError])











  

  // const placeBet = async (e) => {
  //   e.preventDefault();
  //   // You should have Ethereum provider (like MetaMask) installed
  //   if (typeof window.ethereum !== 'undefined') {
  //     const provider = new ethers.BrowserProvider(window.ethereum);
  //     const signer = await provider.getSigner();
  //     const contract = new ethers.Contract(betAddress, BetContract.abi, signer);

  //     try {
  //       const transaction = await contract.createBet(
  //         userId,
  //         expectedScore,
  //         0,
  //         { value: ethers.parseEther(betAmount) }
  //       );
  //       await transaction.wait();
  //       alert('Bet placed successfully!');
  //     } catch (error) {
  //       console.error('Error placing bet:', error);
  //       alert('Failed to place bet: ' + error.message);
  //     }
  //   } else {
  //     alert('Ethereum wallet is not connected');
  //   }
  // };



  const [isValid, setIsValid] = useState(true);
const validateInput = (value) => {
  const regex = /^\d+$/; // Regex to check for numeric input
  return regex.test(value);
};

const handleExpectedScoreChange = (e) => {
  const value = e.target.value;
  if (value === '' || validateInput(value)) { 
      setExpectedScore(value);
      setIsValid(true); 
  } else {
      setIsValid(false); 
  }
};



const [isValidAmount, setIsValidAmount] = useState(true); 

// Validate the bet amount
const validateBetAmount = (value) => {
    const regex = /^\d+(\.\d{1,2})?$/; 
    if (value === '' || (regex.test(value) && parseFloat(value) >= 0.1 && parseFloat(value) <= 0.5)) {
        setIsValidAmount(true);
        return true;
    } else {
        setIsValidAmount(false);
        return false;
    }
};


const handleBetAmountChange = (e) => {
    setBetAmount(e.target.value);
};

// Validate on blur
const handleBlur = () => {
    if (!validateBetAmount(betAmount)) {
        setBetAmount(''); 
    }
};


const [inputError,setInputError] = useState('')

const validateInputs = () => {
  if (selectedTeam === "") {
    setInputError("You must select a team.");
      return false;
  }
  if (!isValidAmount || !betAmount) {
    setInputError("Invalid bet amount. Please enter a value between 0.1 and 0.5 ETH.");
      return false;
  }
  if (!isValid || !expectedScore) {
    setInputError("Invalid expected score. Please enter a valid score.");
      return false;
  }
  setInputError(""); // Clear any previous error messages if all checks pass
  return true;
};



  

  return (
    <>
    



<div>

<Modal
 maskClosable={false}
  title={title}
  // visible={open}
  open={open}

  // onOk={this.handleOk}
  // onCancel={()=>{
  //   setBetAmount('')
  //   setExpectedScore('')
  //   setSelectedTeam('')
  //   setErrorMessage('')
  //   setMessage('')
  //   setOpen(false)
  //   setLoading(false)
  // }}
closeIcon={false}
  footer={[]}
>


{title === 'Create a Bet'?
  <>


 
 {/* <form > */}
<div className="mb-4 md:w-full" >
  <p className='text-blue-600 text-center'>Platform Fee will deduct from bet amount</p>
  <p className='text-green-600'>{message&&message}</p>   
      <p className='text-red-600'>{errorMessage&&errorMessage}</p>

      <p className='text-red-600'>{inputError&&inputError}</p>
            <label htmlFor="email" className="block text-xs mb-1">Your Address</label>
            
            <input 
            disabled
            className="w-full border rounded p-2 outline-none focus:shadow-outline" value={currentAddress} />
          </div>

          {/* <div className="mb-4 md:w-full mt-4">
            <label htmlFor="email" className="block text-xs mb-1">Amount</label>
            
            <input
        type="number"
        className="w-full border rounded p-2 outline-none focus:shadow-outline" 
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
        placeholder="User ID"
        required
      />
          </div> */}

         


          <div className="mb-6 md:w-full">
            <label htmlFor="password" className="block text-xs mb-1">Team</label>
         <select value={selectedTeam}
         className="w-full border rounded p-2 outline-none focus:shadow-outline" 
         onChange={(e) => setSelectedTeam(e.target.value)} required>
        <option value="">Select a team</option>
        <option value={0}>{getCreated?.team_one?.name}</option>
            <option value={1}>{getCreated?.team_two?.name}</option>
      </select>
         {/* <p className='text-red-600'>{errors?.creator_team&&'Team is required'}</p> */}
         </div>

         <div className="mb-4 md:w-full mt-4">
            <label htmlFor="email" className="block text-xs mb-1">Amount</label>
            
           {/* <input
        type="text"
        className="w-full border rounded p-2 outline-none focus:shadow-outline" 
        value={betAmount}
        onChange={(e) => setBetAmount(e.target.value)}
        placeholder="Bet Amount (ETH)"
        required
      /> */}

<input
                type="text"
                className={`w-full border rounded p-2 outline-none focus:shadow-outline ${!isValidAmount ? 'border-red-500' : ''}`}
                value={betAmount}
                onChange={handleBetAmountChange}
                onBlur={handleBlur}
                placeholder="Bet Amount (ETH)"
                required
            />
            {!isValidAmount && <p className="text-red-500">Invalid amount: Please enter a value between 0.1 and 0.5 ETH.</p>}
          </div>


          <div className="mb-4 md:w-full mt-4">
            <label htmlFor="email" className="block text-xs mb-1">Platform Fee</label>
            
            <input 
            className="w-full border rounded p-2 outline-none focus:shadow-outline"   type='text' disabled value='5 %'/>
          </div>

          {/* <div className="mb-4 md:w-full mt-4">
            <label htmlFor="email" className="block text-xs mb-1">Amount After Fee</label>
            
            <input 
            className="w-full border rounded p-2 outline-none focus:shadow-outline"   type='text' disabled value={amountAfterFee} />
          </div> */}

        

          

          <div className="mb-4 md:w-full mt-4">
            <label htmlFor="email" className="block text-xs mb-1">Expected Score</label>
            
         

<input
                type="text" // Use 'text' to manage input formatting
                className={`w-full border rounded p-2 outline-none focus:shadow-outline ${!isValid ? 'border-red-500' : ''}`}
                value={expectedScore}
                onChange={handleExpectedScoreChange}
                placeholder="Expected Score"
                required
            />
            {!isValid && <p className="text-red-500">Invalid input: Please enter a valid score.</p>}
          </div>

          <div className='flex justify-end'>
            <div>
            <Button className='border-2 border-black rounded-md  ml-4 h-10' disabled={dataLoading || loading} onClick={()=>{
                setBetAmount('')
                setExpectedScore('')
                setSelectedTeam('')
                setErrorMessage('')
                setMessage('')
            setLoading(false)
              setOpen(false)}}>Cancel</Button>
            </div>

            <div>
            <Button className='border-2 border-black rounded-md bg-green-500 ml-4 h-10 ' loading={dataLoading || loading} onClick={createBet} disabled={dataLoading || loading}>{dataLoading || loading ? 'Processing...':'Pay And Create'}</Button>

            </div>
          {/* <button type="submit">Place Bet</button> */}
          </div>
          {/* </form> */}
          
  </>:
  <>
  <div className="mb-4 md:w-full" key={2}>
  <p className='text-blue-600 text-center'>Platform Fee will deduct from bet amount</p>
  <p className='text-green-600'>{message&&message}</p>   
      <p className='text-red-600'>{errorMessage&&errorMessage}</p>
      <p className='text-red-600'>{inputError&&inputError}</p>
            <label  className="block text-xs mb-1">Creator Address</label>
            
            <input 
            disabled
            className="w-full border rounded p-2 outline-none focus:shadow-outline" 
            type='text'
            value={getJoin?.creator_add}
            name='creator address'
              />
          </div>

          <div className="mb-4 md:w-full">
            <label htmlFor="email" className="block text-xs mb-1">Your Address</label>
            
            <input 
            disabled
            className="w-full border rounded p-2 outline-none focus:shadow-outline" 
            value={currentAddress} name="address" placeholder="address"/>
          </div>

          <div className="mb-6 md:w-full">
            <label htmlFor="password" className="block text-xs mb-1">Team</label>
         <select value={selectedTeam}
         className="w-full border rounded p-2 outline-none focus:shadow-outline" 
         onChange={(e) => setSelectedTeam(e.target.value)} required>
        <option value="">Select a team</option>
        <option value={0}>{getJoin?.match?.team_one?.name}</option>
            <option value={1}>{getJoin?.match?.team_two?.name}</option>
      </select>
         {/* <p className='text-red-600'>{errors?.creator_team&&'Team is required'}</p> */}
         </div>

         <div className="mb-4 md:w-full mt-4">
            <label  className="block text-xs mb-1">Amount To Pay</label>
            
            <input 
            {...registerJoiner('joiner_team', { required: true })}
            className="w-full border rounded p-2 outline-none focus:shadow-outline"  disabled type='number' value={getJoin?.amount}/>
            <p className='text-red-600'>{errorsJoiner?.joiner_team&&'Team is required'}</p>
          </div>

          <div className="mb-4 md:w-full mt-4">
            <label htmlFor="email" className="block text-xs mb-1">Platform Fee</label>
            
            <input 
            className="w-full border rounded p-2 outline-none focus:shadow-outline"   type='text' disabled value='5 %'/>
          </div>


          {/* <div className="mb-4 md:w-full mt-4">
            <label htmlFor="email" className="block text-xs mb-1">Amount After Fee</label>
            
            <input 
            className="w-full border rounded p-2 outline-none focus:shadow-outline"   type='text' disabled value={amountAfterFee} />
          </div> */}

          <div className="mb-4 md:w-full mt-4">
            <label htmlFor="email" className="block text-xs mb-1">Expected Score</label>
            
         

<input
                type="text" // Use 'text' to manage input formatting
                className={`w-full border rounded p-2 outline-none focus:shadow-outline ${!isValid ? 'border-red-500' : ''}`}
                value={expectedScore}
                onChange={handleExpectedScoreChange}
                placeholder="Expected Score"
                required
            />
            {!isValid && <p className="text-red-500">Invalid input: Please enter a valid score.</p>}
          </div>

          <div className='flex justify-end'>
            <div>
            <Button className='border-2 border-black rounded-md  ml-4 h-10' disabled={dataLoading || loading} onClick={()=>{
                setBetAmount('')
                setExpectedScore('')
                setSelectedTeam('')
                setErrorMessage('')
                setMessage('')
            setLoading(false)
              setOpen(false)}}>Cancel</Button>
            </div>

            <div>
            <Button className='border-2 border-black rounded-md bg-green-500 ml-4 h-10 px-2' loading={dataLoading || loading} onClick={joinBet} disabled={dataLoading || loading} >{dataLoading || loading ? 'Processing...':'Pay And Join'}</Button>

            </div>
          {/* <button type="submit">Place Bet</button> */}
          </div>
  </>
  }


</Modal>





<Modal
 maskClosable={false}
  title={title}
  // visible={open}
  open={openSuccessModal}
  // onOk={this.handleOk}
  closeIcon={false}
  // onCancel={()=>{
  //   setLoading(false)
  //   setErrorMessage('')
  //   setMessage('')
  //   setBetAmount('')
  //   setExpectedScore('')
  //   setSelectedTeam('')
  //   setOpenSuccessModal(false)}}
  footer={[<>
  <button className='px-3 py-2 border-2 border-black rounded-md' onClick={()=>{
      setBetAmount('')
      setExpectedScore('')
      setSelectedTeam('')
      setErrorMessage('')
      setMessage('')
  setLoading(false)
    setOpenSuccessModal(false)}}>Close</button>
  
  </>]}
>


<div className="mb-4 md:w-full" >
  <h3 className='text-green-600 text-center'>{successModalMessage}</h3>
  </div>

  


</Modal>





</div>
</>
  )
}

export default ModalComp