
import {
  BrowserRouter as Router,
  Route,
  Link,
  useNavigate,
  useParams
} from "react-router-dom";
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { ethers } from "ethers";

import BetContract from '../ABI/Bet.json'

import Web3 from "web3";
import { Button, Modal } from "antd";
import './header.css'
import { DownOutlined, WalletOutlined,LogoutOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';


const Header=()=> {


  

  const navigate = useNavigate()


  const [isMetaMaskInstalled, setIsMetaMaskInstalled] = useState(false);


  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
        setIsMetaMaskInstalled(true);
    } else {
        setIsMetaMaskInstalled(false);
        console.error("MetaMask is not installed. Please install it to use this application.");
    }
}, []);

  let userData = localStorage.getItem('data')
  let getUser = userData? JSON.parse(userData):[]


const [connected, toggleConnect] = useState(false);
const location = useLocation();
const [currAddress, updateAddress] = useState('0x');

useEffect(()=>{
  if(currAddress){
    localStorage.setItem('currAddress',currAddress.split(',')[0])
  }
},[currAddress])

const [balance,setBalance] = useState('')

async function getAddress() {
  // const ethers = require("ethers");
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const address = (await signer).getAddress()
  const balance = await provider.getBalance(address);
  setBalance(balance)
  const res = await provider.send("eth_requestAccounts", []);
  updateAddress(res.toString());
}



async function getBalance(){
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  const address = (await signer).getAddress()
  const balance = await provider.getBalance(address);
  setBalance(balance)
}




const connectWebsite = async()=> {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    if(chainId !== '0xaa36a7' || chainId !== '31337')
    {
      //alert('Incorrect network! Switch your metamask network to Rinkeby');
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0xaa36a7' }],
     })
    }
    
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' })
      .then(() => {
        // updateButton();
        getAddress();
        getUserBalance()
        getAdminBalance()
        window.location.replace(location.pathname)
        window.location.reload()
      });
  } catch (error) {
      console.error('User denied account access');
  }
    
}



useEffect(()=>{
  getUserBalance()
  getAdminBalance()
  getAddress()
},[currAddress])

  useEffect(() => {
    if(window?.ethereum == undefined)
      return;
    let val = window?.ethereum.isConnected();
    if(val)
    {
      getAddress();
      toggleConnect(val);
      getAdminBalance()
      getUserBalance()
      // updateButton();
    }

    window.ethereum.on('accountsChanged', function(accounts){
      window.location.replace(location.pathname)
      window.location.reload()
    })
  },[]);


  let finalBalance = (Number(balance)/1000000000000000000)


  const [isMenuOpen, setIsMenuOpen] = useState(false);



const [adminBalance,setAdminBalance] = useState(0.0)

const [userBalance,setUserBalance] = useState(0.0)
  
async function getAdminBalance() {
  if (typeof window.ethereum !== 'undefined') {
    const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const testContract = new ethers.Contract(betAddress, BetContract.abi, signer);

      try {
          
          const transaction = await testContract.totalFeesCollected();
          const finalValue = ethers.formatEther(transaction)
         
          setAdminBalance(Number(finalValue).toFixed(4))

      } catch (error) {
          console.error("Error creating bet", error);
      }
  } else {
      console.error("Ethereum wallet is not available");
  }
}


async function getUserBalance() {
  if (typeof window.ethereum !== 'undefined') {
    const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const testContract = new ethers.Contract(betAddress, BetContract.abi, signer);

      try {
          if(currAddress !== "0x"){
          const transaction = await testContract.checkWinnings(currAddress.split(',')[0]);

          const finalValue = ethers.formatEther(transaction)
         
          setUserBalance(Number(finalValue).toFixed(4))
          // setUserBalance(ethers.formatEther(transaction))
        }

      } catch (error) {
          console.error("Error creating bet", error);
      }
  } else {
      console.error("Ethereum wallet is not available");
  }
}


const [infoModal,setInfoModal] =useState(false)
    const [infoMessage,setInfoMessage] =useState('') 

    const [loading,setLoading] = useState(false)


async function withdrawAdmin() {
  if (typeof window.ethereum !== 'undefined') {
    const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const testContract = new ethers.Contract(betAddress, BetContract.abi, signer);
  if(adminBalance == 0.000){
    setInfoMessage('Insufficient Amount')
          setInfoModal(true)
  }else{
    setLoading(true)
      try {
          if(currAddress != '0x'){
          const transaction = await testContract.withdrawFees();
          await transaction.wait()
          setLoading(false)
          setInfoMessage('Successfully Withdrawed')
          setInfoModal(true)
          // setUserBalance(ethers.formatEther(transaction))
        }

      } catch (error) {
          console.error("Error creating bet", error);
          setInfoMessage(error?.info?.error?.message)
          setInfoModal(true)
      }
    }
  } else {
      console.error("Ethereum wallet is not available");
  }
}


async function withdrawUser() {
  if (typeof window.ethereum !== 'undefined') {
    setLoading(true)
    const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const testContract = new ethers.Contract(betAddress, BetContract.abi, signer);

      try {
          if(currAddress != '0x'){
          const transaction = await testContract.withdrawWinnings();
          await transaction.wait()
          setLoading(false)
          setInfoMessage('Successfully Withdrawed')
          setInfoModal(true)
          // setUserBalance(ethers.formatEther(transaction))
        }

      } catch (error) {
          console.error("Error creating bet", error?.info?.error?.message);
          setInfoMessage(error?.info?.error?.message)
          setInfoModal(true)
          setLoading(false)
      }
  } else {
      console.error("Ethereum wallet is not available");
  }
}





  let betAddress = '0xd692f06227AF55A7FDb9F97389F6331c3dB3eAa6'


 

const items = [
  {
    label:  <div className='text-orange-700 text-bold text-center  text-sm'>
    Hard refresh to get updated Info ( Ctrl + Shift + R )
  </div> ,
    key: '4',
  },
  {
    type: 'divider',
  },
  {
    label: <button className='w-full enableEthereumButton bg-gray-500 hover:bg-gray-700 text-white font-bold m-1 py-1 px-4 rounded text-sm'
    >Pending Balance : {getUser?.name == 'Admin'?adminBalance:userBalance} ETH
    
    <Button className="px-2 border-1 bg-orange-500 mx-2  p-0 rounded-lg" disabled={loading} loading={loading} onClick={getUser?.name == 'Admin'? withdrawAdmin : withdrawUser}>Withdraw</Button>
    </button>,
    key: '0',
  },
  {
    label: <button className='w-full enableEthereumButton bg-blue-700 hover:bg-blue-700 text-white font-bold m-1 py-2.5 px-4 rounded text-sm' >Balance: {connected? String(finalBalance).slice(0,5):0.000} ETH</button>,
    key: '1',
  },
  {
    type: 'divider',
  },
  {
    label: <button className='w-full enableEthereumButton bg-green-600 hover:bg-green-600 text-white font-bold m-1 py-2.5 px-4 rounded text-sm'
    onClick={()=>connectWebsite()}>{connected? "Connected":"Connect Wallet"}</button>,
    key: '3',
  },

  {
    label:  <div className='text-black text-bold text-center  text-sm'>
    {currAddress !== "0x" ? "Connected to":"Not Connected. Please login to view Bets"} {currAddress !== "0x" ? (currAddress.substring(0,4)+'...'+currAddress.substring(42,38)):""}
  </div> ,
    key: '4',
  },
];




    return (
      <>
       

<header className="mb-20">
  <nav className="bg-white fixed w-full z-20 top-0 left-0 border-b border-gray-200 dark:border-gray-600 lg:shadow-sm lg:shadow-black">
    <ul className="navigation max-w-[96vw] flex flex-wrap justify-between items-center relative mx-auto ">
      <a className="logo" href="#">
        <h3 className="font-bold text-2xl">Let's Bet</h3>
      </a>

      <input type="checkbox" id="check" />

      <span className="menu py-3 flex [&>li]:pl-2 [&>li>a]:text-center items-center overflow-auto [&>li>a]:relative [&>li>a]:transition [&>li>a]:duration-200 [&>li>a]:ease-in-out [&>li>a]:font-medium [&>li>a]:text-lg">
      <li ><Link className="ml-2 lg:border-r-2 lg:border-b-0 sm:border-b-2 border-b-2  border-teal-600 px-2 hover:text-green-600 hover:border-r-green-600" to="/">Home</Link></li>
        <li><Link className="ml-2 lg:border-r-2 lg:border-b-0 sm:border-b-2 border-b-2 border-teal-600 px-2 hover:text-green-600 hover:border-r-green-600" to="/Created">Created Bets</Link></li>
        <li><Link className="ml-2 lg:border-r-2 lg:border-b-0 sm:border-b-2 border-b-2 border-teal-600 px-2 hover:text-green-600 hover:border-r-green-600" to="/Joined">Joined Bets</Link></li>
        <li><Link className="ml-2 lg:border-r-2 lg:border-b-0 sm:border-b-2 border-b-2 border-teal-600 px-2 hover:text-green-600 hover:border-r-green-600" to="/Declared">Declared Bets</Link></li>
        {getUser?.name == 'Admin'?

        <>
        <li><Link className="ml-2 lg:border-r-2 lg:border-b-0 sm:border-b-2 border-b-2 border-teal-600 px-2 hover:text-green-600 hover:border-r-green-600" to="/Admin">Dashboard</Link></li>
        </>:
      null
    }

 {isMetaMaskInstalled ? (
        <Dropdown placement="bottom" className="ml-5 lg:border-2 mt-10 md:mt-10 lg:mt-0 lg:border-0 sm:border-2 border-b-2 border-teal-600 px-2 py-1 hover:text-green-600 hover:border-green-600 rounded-md bg-green-600 text-white hover:text-white font-medium" menu={{ items }} 
        trigger={['click']} 
         >
    <a onClick={(e) => e.preventDefault()}>
      <Space className="px-2">
      <WalletOutlined/>
        Wallet {connected? String(finalBalance).slice(0,5):0.000} ETH
        <DownOutlined className="ml-3"/>
      </Space>
    </a>
  </Dropdown>)

: (
  <div className="text-red-500">MetaMask is not installed. <a href="https://metamask.io/download.html" target="_blank" rel="noopener noreferrer">Install MetaMask</a></div>
)} 

 <li className=''>
                <button className='enableEthereumButton bg-red-600 hover:bg-red-600 text-white font-bold py-2 px-4 rounded text-sm' onClick={()=>{
                  localStorage.removeItem('joinData')
                  localStorage.removeItem('data')
                  localStorage.removeItem('createData')
                  localStorage.removeItem('currAddress')
                  navigate('/Login')
                }}><LogoutOutlined className="mr-2"/> Logout</button>
              </li>



      

        <label htmlFor="check" className="close-menu">X</label>
      </span>

     

      <label htmlFor="check" className="open-menu">
         <svg
            xmlns="http://www.w3.org/2000/svg"
            id="menu-button"
            className="h-10 w-6 cursor-pointer  block"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>

          </label>
    </ul>
  </nav>
</header>



        <Modal
 maskClosable={false}
  title={'Status'}
  // visible={open}
  open={infoModal}
  // onOk={this.handleOk}
  onCancel={()=>{
    setInfoMessage('')
    setInfoModal(false)}}
  footer={[<>
  <button className='px-3 py-2 border-2 border-black rounded-md' onClick={()=>{
      setInfoMessage('')
      setInfoModal(false)
      getUserBalance()
      getAdminBalance()
  }}>Close</button>
  
  </>]}
>


<div className="mb-4 md:w-full" >
  <h3 className='text-green-600 text-center'>{infoMessage}</h3>
  </div>

  


</Modal>

      </>
    );
  }

  export default Header;