import { Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Header from '../../Components/Header';
// import './App.css';

interface Bet {
  creator: string;
  match: string;
  creator_team: string;
  amount: string;
  status: string;
}

function Admin() {
  const [betList, setBetList] = useState<Bet[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/bet_list/`);
        const data = await response.json();
        setBetList(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);


  return (
    <>
    <Header/>
    <div className="App">
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-10">
          <div>
            <h1 className="text-2xl font-semibold leading-tight">Bets</h1>
          </div>
          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
              <table className="min-w-full leading-normal">
                <thead className='text-center'>
                  <tr >
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-blue-500 text-white text-left text-m font-semibold uppercase tracking-wider text-center">ID</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-blue-500 text-white text-left text-m font-semibold uppercase tracking-wider text-center">Match</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-blue-500 text-white text-left text-m font-semibold uppercase tracking-wider text-center">Creator</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-blue-500 text-white text-left text-m font-semibold uppercase tracking-wider text-center">Status</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-blue-500 text-white text-left text-m font-semibold uppercase tracking-wider text-center">Amount</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-blue-500 text-white text-left text-m font-semibold uppercase tracking-wider text-center">Commission</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-blue-500 text-white text-left text-m font-semibold uppercase tracking-wider text-center">Date</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-blue-500 text-white text-left text-m font-semibold uppercase tracking-wider text-center">Winning Team</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-blue-500 text-white text-left text-m font-semibold uppercase tracking-wider text-center">Real Score</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-blue-500 text-white text-left text-m font-semibold uppercase tracking-wider text-center">Joiners</th>
                    <th className="px-5 py-3 border-b-2 border-gray-200 bg-blue-500 text-white text-left text-m font-semibold uppercase tracking-wider text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {betList?.map((bet:any, index) => {
                    let joiners = bet?.joiners.map((e:any) => e?.joiner_name)
                    let list = joiners?.join(',')

                    

                    return(
                    <tr key={index}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {index+1}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <b>{bet?.match?.team_one?.name}</b> v/s <b>{bet?.match?.team_two?.name}</b>
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {bet?.creator.name}
                      </td>

                      {bet.status === 'OPEN'?
                    <>
                    <td className="px-5 py-5 border-b text-green-600 border-gray-200 bg-white text-sm">
                          {bet?.status}
                      </td>
                    </>:
                    <>
                    <td className="px-5 py-5 text-red-500 border-b border-gray-200 bg-white text-sm">
                          {bet?.status}
                      </td>
                    </>  
                    }
                      {/* <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {bet.status === 'Open'?<p cl>{bet.status}</p>:<p>{bet.status}</p>}
                      </td> */}
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {bet?.amount}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {bet?.commission}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {new Date(bet?.created_at).toLocaleDateString('en-GB')}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {bet?.winning_team ? bet?.winning_team.name : 'N/A'}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {bet?.score != 0 ? bet?.score :'N/A'}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          {list?.length >0 ?`${list?.slice(0,20)}...`:'-'}
                      </td>

                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                          <Link to={`/editBet/${bet?.id}`}> <Button 
                          // disabled={bet?.status == 'CLOSED'} 
                          className='border-1 border-blue-500'>Update Real Score</Button></Link>
                      </td>
                    </tr>
                  )})}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Admin;
