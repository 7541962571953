import React, { useEffect, useState } from 'react';
import Header from '../Components/Header';
import { useFetch } from '../API/Api';


const Created=()=> {



  let userData = localStorage.getItem('data')
  let extractData = userData? JSON.parse(userData):[]
  let userId = extractData?.id

  let apinameForBets = `user_info/${userId}`

  const {
    isLoading: fetchLoading,
    isError,
    data: fetchAllData,
    error,
    isFetching,
    refetch,
    isSuccess,
    isPreviousData,
    isRefetching,
  } = useFetch(apinameForBets);



  const [data,setData]:any = useState([])





  useEffect(()=>{
    if(fetchAllData){
      // let sortedData;
      // sortedData = fetchAllData?.joiners?.sort((a:any, b:any) => b?.bet_id - a?.bet_id);
      setData(fetchAllData?.user_bets)
    }
  },[fetchAllData])




  return (
    <>
     <Header/>

   
{data?.map((e:any)=>{
 let joiners = e?.joiners?.map((e:any)=>e?.joiner_name)


 const date = new Date(e?.created_at);

const options:any = { 
  weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',
  hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true, timeZoneName: 'short'
};

const formattedDate = date.toLocaleString('en-US', options);

 return(

<div key={e?.id} className="container mx-auto flex justify-center pt-10">
    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 w-96 md:w-96 lg:w-1/2 	">
      <div className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white border-2 border-green-200 shadow-xl">
      <div className="flex w-full items-center justify-between space-x-6 p-6">
        <div className="flex-1 truncate">
          <div className='flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center items-center  space-x-3 justify-between'>
            <div>
            <span className='text-green-600'>Created By You</span>
            </div>

            <div>
            <span className='text-green-600'>Created At - {formattedDate.split('GMT')[0]}</span>
            </div>
            </div>
          <div className="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center items-center  space-x-3 justify-between">
            <div >
            <span className="truncate text-sm font-medium text-gray-900">{e?.match?.team_one} Vs {e?.match?.team_two}</span>
            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 ml-3 py-0.5 text-xs font-medium text-red-600 ring-1 ring-inset ring-green-600/20">World Cup</span>
            </div>
            <div>
            <h3 className="pt-3 truncate text-sm font-medium text-blue-600">Entry Amount - {e?.amount} ETH</h3>
            </div>
          </div>




          <div className="flex grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 text-center items-center  space-x-3 justify-between">
            <div >
            <span className="mt-3 truncate text-sm text-center text-gray-500">Player Capacity - {joiners?.length+1}/{e?.match?.player_capacity}</span>

            </div>
            <div>
            <h3 className="pt-3 truncate text-sm font-medium text-black"><span className='text-orange-600'>Joiners</span> - {joiners?.join(' , ')}</h3>
            </div>
          </div>
  
  
         
          
        </div>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200">
          <div className="-ml-px flex w-0 flex-1 bg-gray-200">
            <button disabled className=" cursor-pointer relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-400">
              Created
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  )
 
 
 
})}
 
</>
  );
}

export default Created;
