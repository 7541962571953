import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import Login from './Pages/Login';
import Register from './Pages/Register';
import Admin from './Pages/Admin/Admin';
import User from './Pages/User';
import Created from './Pages/Created';
import Joined from './Pages/Joined';
import { QueryClient, QueryClientProvider } from 'react-query';
import EditBet from './Pages/Admin/EditBet';
import Declared from './Pages/Declared';
import {PrivateRoutes} from '../src/Pages/PrivateRoute'
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient()
root.render(
  <>
  <QueryClientProvider client={queryClient} contextSharing={true}>
  <Router>
    <Routes>

    <Route  element={<PrivateRoutes/>}>
    <Route path='/'  element={<App/>}/>
      {/* <Route path='/Login' element={<Login/>}/> */}
      
      <Route path='/Admin' element={<Admin/>}/>
      <Route path='/editBet/:id' element={<EditBet/>}/>
      <Route path='/User' element={<User/>}/>

      <Route path='/Created' element={<Created/>}/>
      <Route path='/Joined' element={<Joined/>}/>
      <Route path='/Declared' element={<Declared/>}/>
      <Route path="*" element={<App/>} />
          </Route>
          <Route path='/Login' element={<Login/>}/>
          <Route path='/Register' element={<Register/>}/>
          <Route path="*" element={<Navigate to="/Login" />} />

      {/* <Route path='/' element={<App/>}/>
      <Route path='/Register' element={<Register/>}/>
      <Route path='/Admin' element={<Admin/>}/>
      <Route path='/editBet/:id' element={<EditBet/>}/>
      <Route path='/User' element={<User/>}/>

      <Route path='/Created' element={<Created/>}/>
      <Route path='/Joined' element={<Joined/>}/>
      <Route path='/Declared' element={<Declared/>}/> */}
    </Routes>
    {/* <App /> */}
    </Router>
    </QueryClientProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
